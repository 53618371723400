import React from 'react'
import Image from 'next/legacy/image'
import { styled } from '../../../../stitches.config'

export interface PostItNoteProps {
    backgroundImage?: any
    personImage?: string
    postItText: string
    nameText: string
    wrapperCss?: any
    isBlurred?: boolean
    priority?: boolean
}

const Wrapper = styled('div', {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.5rem',
    height: '18rem',
    width: '17rem',
    transform: 'scale(90%)',
    '@sm': {
        transform: 'scale(95%)',
    },
    '@md': {
        transform: 'none',
    },
})
const BottomBox = styled('div', {
    display: 'flex',
    ai: 'center',
    jc: 'center',
    minHeight: 'fit-content',
    minWidth: '90%',
    py: '4%',
    img: {
        borderRadius: '50%',
        objectFit: 'cover',
    },
})

const PostItNote = ({
    postItText,
    nameText,
    backgroundImage = 'https://cdn.builder.io/api/v1/image/assets%2F937d9384b7804bcf8697c1e4db8d0919%2F3d07abc16a68425bb9073efc280d0e67',
    personImage,
    wrapperCss,
    isBlurred,
    priority,
}: PostItNoteProps) => {
    const PostItText = styled('p', {
        textAlign: 'center',
        lineHeight: '1.45rem',
        maxWidth: '85%',
        paddingTop: '33%',
        maxHeight: '76%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        margin: 0,
        filter: isBlurred ? 'blur(4px)' : 'unset',
    })
    const NameText = styled('p', {
        fontSize: '.85rem',
        paddingLeft: personImage ? '7px' : 0,
        minWidth: '30%',
        maxWidth: '65%',
        margin: 0,
        filter: isBlurred ? 'blur(4px)' : 'unset',
        textTransform: 'capitalize',
        '&::before': {
            content: '— ',
        },
    })
    return (
        <Wrapper style={wrapperCss ?? {}} data-testid={'postIt'}>
            <Image
                src={backgroundImage}
                layout={'fill'}
                style={{
                    zIndex: -1,
                    transform: 'scale(1.1, 1.3)',
                }}
                alt={'Post-it note with message'}
                priority={!!priority}
            />
            <PostItText data-testid={'postText'}>{postItText}</PostItText>
            <BottomBox>
                {personImage && (
                    <Image
                        src={personImage}
                        height={35}
                        width={35}
                        alt={'Post-it image'}
                        priority={!!priority}
                    />
                )}
                <NameText data-testid={'postName'}>{nameText}</NameText>
            </BottomBox>
        </Wrapper>
    )
}

export default PostItNote
